import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider } from "react-helmet-async";

const container = document.getElementById("root");

if (!container) throw new Error("Failed to find the root element");

const root = createRoot(container); // Now TypeScript knows container is not null

const helmetContext = {};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider context={helmetContext}>
        <CssBaseline />
        <App />
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);
