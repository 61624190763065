import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

export const Footer: React.FC = () => {
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);
  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);

  return (
    <AppBar position='static' color='primary' sx={{ mt: 4, top: 'auto', bottom: 0 }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography variant='body1' color='inherit'>
            © {new Date().getFullYear()} KargoKloud
          </Typography>
          <Typography variant='body2' color='inherit'>
            P.O. Box 4860, Augusta, GA 30903
          </Typography>
        </Box>
        <Box>
          <Button color='inherit' onClick={handleOpenPrivacy}>
            Privacy Policy
          </Button>
          <Dialog open={openPrivacy} onClose={handleClosePrivacy} scroll='paper'>
            <DialogTitle>{'Privacy Policy'}</DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText tabIndex={-1}>Effective Date: March 13, 2022</DialogContentText>
              <DialogContentText tabIndex={-1}>
                1. Introduction: Thank you for choosing KargoKloud. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this
                privacy policy, or our practices with regards to your personal information, please contact us at contact@kargokloud.com.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                2. Information We Collect: When you visit our website, we may collect certain data about you such as your Internet Protocol (IP) address, browser type, and usage data. Additionally, if
                you decide to register for our service, we collect your name, email address, company affiliation, and phone number.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                3. Use of Your Information: We use the information we collect to operate and maintain our services, send you marketing communications, respond to your questions and concerns, and to
                improve our overall service.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                4. Sharing Your Information: Your information is not sold to third parties. It may be shared with partners who help us provide our services to you; for example, analytics providers and
                customer service organizations.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                5. Data Security: We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                6. Changes to This Policy: We may update this privacy policy to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the
                e-mail address specified in your account) or by means of a notice on this Website prior to the change becoming effective.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                7. Contact Us: If you have any questions about this Policy, please contact us at contact@kargokloud.com or by mail at:
                <br />
                P.O. Box 4860, Augusta, GA 30903, USA.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePrivacy}>Close</Button>
            </DialogActions>
          </Dialog>

          <Button color='inherit' onClick={handleOpenTerms}>
            Terms of Use
          </Button>
          <Dialog open={openTerms} onClose={handleCloseTerms} scroll='paper'>
            <DialogTitle>{'Terms of Use'}</DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText tabIndex={-1}>Effective Date: January 3, 2021</DialogContentText>
              <DialogContentText tabIndex={-1}>
                1. Agreement to Terms: By accessing and using KargoKloud, you agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree with any of these terms, you are
                prohibited from using or accessing this site.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                2. Intellectual Property: The content, layout, design, data, databases, and graphics on this website are protected by U.S. and international copyright laws. You may not copy,
                distribute, make available to the public, or create derivative works from our content without our prior written consent.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                3. Acceptable Use: You agree not to use KargoKloud in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the network. You
                also agree not to use the website in any way which is unlawful, illegal, fraudulent, or harmful.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                4. Registration: You may need to register to use parts of our website. When registering, you will provide truthful and accurate information, and you will update such information to
                keep it current. You are responsible for maintaining the confidentiality of your account and password.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                5. Termination: We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you
                breach the Terms of Use.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                6. Governing Law: These terms and conditions are governed by and construed in accordance with the laws of the State of Georgia, USA, and you irrevocably submit to the exclusive
                jurisdiction of the courts in that State.
              </DialogContentText>
              <DialogContentText tabIndex={-1}>
                7. Contact Us: If you have any questions about these Terms, please contact us at contact@kargokloud.com or by mail at:
                <br />
                P.O. Box 4860, Augusta, GA 30903, USA.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTerms}>Close</Button>
            </DialogActions>
          </Dialog>

          <ScrollLink to='contactForm' spy={true} smooth={true} offset={-70} duration={500}>
            <Button color='inherit'>Request Demo</Button>
          </ScrollLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
