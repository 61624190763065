import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-scroll';

export const Header: React.FC = () => (
  <AppBar position='static'>
    <Toolbar>
      <img src='/kk-icon.svg' alt='Logo' style={{ marginRight: '20px', height: '30px' }} />
      <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
        KargoKloud
      </Typography>
      <Link to='contactForm' spy={true} smooth={true} offset={-70} duration={500}>
        <Button color='inherit'>Request Demo</Button>
      </Link>
    </Toolbar>
  </AppBar>
);
