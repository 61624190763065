import React from "react";
import { CssBaseline, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Header } from "./components/Header";
import { AboutSection } from "./components/AboutSection";
import { ContactForm } from "./components/ContactForm";
import { FeaturesSection } from "./components/FeaturesSection";
import { PricingSection } from "./components/PricingSection";
import { TestimonialsSection } from "./components/TestimonialsSection";
import { Footer } from "./components/Footer";

export const App: React.FC = () => (
  <>
    <Helmet>
      <title>KargoKloud - Streamline Your Logistics</title>
      <meta
        name="description"
        content="KargoKloud is your solution for efficient and reliable logistics and supply chain management."
      />
    </Helmet>
    <CssBaseline />
    <Header />
    <Container maxWidth="lg">
      <AboutSection />
      <FeaturesSection />
      <PricingSection />
      <TestimonialsSection />
      <ContactForm />
    </Container>
    <Footer />
  </>
);
