import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

const features = [
  {
    title: 'Real-Time Tracking',
    description: 'Monitor your shipments in real-time, ensuring timely deliveries across the globe.',
    image: 'https://source.unsplash.com/random/200x200?sig=1&cargo',
  },
  {
    title: 'Inventory Management',
    description: 'Keep track of your inventory levels, orders, sales, and deliveries all in one platform.',
    image: 'https://source.unsplash.com/random/200x200?sig=2&inventory',
  },
  {
    title: 'Automated Routing',
    description: 'Optimize delivery routes to reduce costs and improve efficiency, automatically adjusting for traffic and weather.',
    image: 'https://source.unsplash.com/random/200x200?sig=3&routing',
  },
  {
    title: 'Analytics and Reporting',
    description: 'Access advanced analytics and reports to make informed decisions about your logistics operations.',
    image: 'https://source.unsplash.com/random/200x200?sig=4&analytics',
  },
  {
    title: 'Customer Portal',
    description: 'Provide your customers with a portal to view their shipment status and manage their orders efficiently.',
    image: 'https://source.unsplash.com/random/200x200?sig=5&customer-portal',
  },
  {
    title: 'Mobile Integration',
    description: 'Manage logistics from anywhere with our fully integrated mobile platform.',
    image: 'https://source.unsplash.com/random/200x200?sig=6&mobile-integration',
  },
  {
    title: 'Warehouse Management',
    description: 'Streamline your warehouse operations with our comprehensive warehouse management system.',
    image: 'https://source.unsplash.com/random/200x200?sig=7&warehouse',
  },
  {
    title: 'Order Fulfillment',
    description: 'Automate order processing and reduce delivery times with our efficient order fulfillment solutions.',
    image: 'https://source.unsplash.com/random/200x200?sig=8&order-fulfillment',
  },
  {
    title: 'Return and Refunds',
    description: 'Handle returns and refunds smoothly with automated processes that save time and improve customer satisfaction.',
    image: 'https://source.unsplash.com/random/200x200?sig=9&returns',
  },
];

export const FeaturesSection: React.FC = () => (
  <Box py={5}>
    <Grid container spacing={2}>
      {features.map(feature => (
        <Grid item xs={12} sm={6} md={4} key={feature.title}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component='img' height='140' image={feature.image} alt={feature.title} />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant='h5' component='div'>
                {feature.title}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);
