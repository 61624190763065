import React from 'react';
import { Box, Typography, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const plans = [
  {
    title: 'Basic',
    price: '$29/mo',
    features: [
      { name: 'Up to 50 users', included: true },
      { name: '500 shipments per month', included: true },
      { name: '2 document types', included: true },
      { name: 'Email support', included: true },
      { name: 'Basic analytics', included: true },
      { name: 'Mobile access', included: false },
    ],
  },
  {
    title: 'Professional',
    price: '$99/mo',
    features: [
      { name: 'Up to 200 users', included: true },
      { name: '2,000 shipments per month', included: true },
      { name: '5 document types', included: true },
      { name: 'Priority email support', included: true },
      { name: 'Advanced analytics', included: true },
      { name: 'Mobile access', included: true },
    ],
    mostPopular: true,
  },
  {
    title: 'Enterprise',
    price: '$249/mo',
    features: [
      { name: 'Unlimited users', included: true },
      { name: 'Unlimited shipments', included: true },
      { name: 'Unlimited document types', included: true },
      { name: '24/7 support', included: true },
      { name: 'Custom analytics', included: true },
      { name: 'Mobile access', included: true },
    ],
  },
];

export const PricingSection: React.FC = () => (
  <Box component={Card} variant='outlined' sx={{ p: 4, mt: 6, mb: 6, borderRadius: 2 }}>
    <Typography variant='h4' gutterBottom>
      Pricing
    </Typography>
    <Grid container spacing={2}>
      {plans.map(plan => (
        <Grid item xs={12} sm={4} key={plan.title}>
          <Card
            sx={{
              position: 'relative',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 20,
              },
              backgroundColor: plan.mostPopular ? 'primary.light' : 'background.paper',
              color: plan.mostPopular ? 'common.white' : 'text.primary',
            }}>
            <CardContent>
              <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {plan.title}
                {plan.mostPopular && (
                  <Typography
                    component='span'
                    sx={{
                      backgroundColor: 'common.white',
                      color: 'primary.main',
                      borderRadius: '10px',
                      px: 1.5,
                      py: 0.5,
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      display: 'inline-block',
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    }}>
                    Most Popular!
                  </Typography>
                )}
              </Typography>
              <Typography variant='h6'>{plan.price}</Typography>
              <List>
                {plan.features.map(feature => (
                  <ListItem key={feature.name}>
                    <ListItemIcon>{feature.included ? <CheckIcon /> : <CloseIcon />}</ListItemIcon>
                    <ListItemText primary={feature.name} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);
