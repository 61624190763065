import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Jumbotron = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundImage: 'url(https://source.unsplash.com/random/1024x768?truck)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.common.white,
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

export const AboutSection: React.FC = () => (
  <Jumbotron>
    <Typography variant='h2' component='h1' sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.6)' }}>
      Drive Your Logistics Forward with KargoKloud
    </Typography>
    <Typography variant='h5' sx={{ textShadow: '1px 1px 3px rgba(0,0,0,0.5)' }}>
      Harness the power of real-time data to streamline your operations.
    </Typography>
  </Jumbotron>
);
