import { Box, Typography, Card, CardContent, Avatar } from '@mui/material';

const testimonials = [
  {
    name: 'Mike Thompson',
    position: 'Logistics Coordinator',
    comment:
      'The real-time tracking feature of KargoKloud has been a game-changer for us, allowing us to keep our customers updated with the latest shipping information. This has significantly increased customer satisfaction.',
    location: 'Cleveland, OH',
    avatar: 'https://source.unsplash.com/random/100x100?sig=10&person',
  },
  {
    name: 'Sara Gomez',
    position: 'Warehouse Supervisor',
    comment:
      "Using the automated routing and warehouse management systems has streamlined our operations and reduced overhead costs. KargoKloud's intuitive platform has made implementation a breeze.",
    location: 'Phoenix, AZ',
    avatar: 'https://source.unsplash.com/random/100x100?sig=11&person',
  },
  {
    name: 'Raj Patel',
    position: 'Operations Analyst',
    comment: 'The analytics and reporting tools provided by KargoKloud have empowered us with actionable insights that have directly contributed to our growth and operational efficiency.',
    location: 'Newark, NJ',
    avatar: 'https://source.unsplash.com/random/100x100?sig=12&person',
  },
];

export const TestimonialsSection: React.FC = () => (
  <Box component={Card} variant='outlined' sx={{ p: 4, mt: 6, mb: 6, borderRadius: 2 }}>
    <Typography variant='h4' gutterBottom>
      Testimonials
    </Typography>
    {testimonials.map(testimonial => (
      <Card key={testimonial.name} sx={{ marginBottom: 2 }}>
        <CardContent>
          <Box display='flex' alignItems='center'>
            <Avatar src={testimonial.avatar} sx={{ marginRight: 2 }} />
            <Box>
              <Typography variant='h6'>
                {testimonial.name} - {testimonial.position}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {testimonial.location}
              </Typography>
            </Box>
          </Box>
          <Typography paragraph>{testimonial.comment}</Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
);
