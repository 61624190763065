import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Card, Grid } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const ContactForm: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formatPhoneNumber(); // Ensure phone number is formatted before submitting
    setSubmitted(true);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handlePhoneNumberBlur = () => {
    formatPhoneNumber();
  };

  const formatPhoneNumber = () => {
    const digits = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters
    if (digits.length === 10) {
      const formatted = `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`;
      setPhoneNumber(formatted);
    }
  };

  return (
    <Box id='contactForm' component={Card} variant='outlined' sx={{ p: 4, mt: 6, mb: 6, borderRadius: 2 }}>
      <Typography variant='h4' gutterBottom>
        Request Demo
      </Typography>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <TextField label='Full Name' variant='outlined' fullWidth margin='normal' required />
          <TextField label='Email Address' type='email' variant='outlined' fullWidth margin='normal' required />
          <TextField label='Company Name' variant='outlined' fullWidth margin='normal' required />
          <TextField
            label='Phone Number'
            type='tel'
            variant='outlined'
            fullWidth
            margin='normal'
            required
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            inputProps={{
              title: 'Phone number should be in the format: (123) 456-7890',
            }}
            placeholder='(123) 456-7890'
          />
          <TextField label='Role in Company' variant='outlined' fullWidth margin='normal' />
          <TextField label='Business Size' variant='outlined' fullWidth margin='normal' />
          <TextField label='Message' multiline rows={4} variant='outlined' fullWidth margin='normal' />
          <Button variant='contained' color='primary' type='submit'>
            Request Demo
          </Button>
        </form>
      ) : (
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
          <Grid item xs={12} sm={3}>
            <MailOutlineIcon style={{ fontSize: 60, display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant='h5' color='text.secondary' align='center'>
              Your request has been received!
              <br />
              <br />
              We'll be in touch soon to schedule your demo.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
